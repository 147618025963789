<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Blade Headers"
                    description="import { HbBladeHeader } from 'hummingbird-aviary';"
                >
                </hb-page-header>
            </hb-header>
        </div>
        <hb-card title="Standard Blade Header Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-0">
                <hb-blade-header
                    title="Placeholder"
                    title-icon="mdi-flash-circle"
                    @back="handleEmittedBackEvent"
                    @close="handleEmittedCloseEvent"
                >
                    <template v-slot:right>
                        <HbBtn class="mr-1" icon @click="doSomething()" tooltip="Bulk Actions" mdi-icon="mdi-format-line-spacing" hover-background-color="#CAEAEA" />
                        <HbBtn class="mr-1" icon @click="doSomething()" tooltip="Save Report" mdi-icon="mdi-content-save" hover-background-color="#CAEAEA" />
                        <HbBtn class="mr-1" icon @click="doSomething()" tooltip="Export Report" mdi-icon="mdi-download" hover-background-color="#CAEAEA" />
                    </template>
                </hb-blade-header>
            </div>
            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-blade-header
    title="Placeholder"
    title-icon="mdi-flash-circle"
    @back="handleEmittedBackEvent"
    @close="handleEmittedCloseEvent"
>
    &lt;template v-slot:right>
        &lt;HbBtn class="mr-1" icon @click="doSomething()" tooltip="Bulk Actions" mdi-icon="mdi-format-line-spacing" />
        &lt;HbBtn class="mr-1" icon @click="doSomething()" tooltip="Save Report" mdi-icon="mdi-content-save" />
        &lt;HbBtn class="mr-1" icon @click="doSomething()" tooltip="Export Report" mdi-icon="mdi-download" />
    &lt;/template>
&lt;/hb-blade-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Nav Menu Blade Header Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-0">
                <hb-blade-header
                    title="Quick Actions"
                    title-icon="mdi-flash-circle"
                    first-column-off
                    left-align-title
                    more-title-space
                    @close="handleEmittedCloseEvent"
                >
                    <template v-slot:right>
                        <HbBtn class="mr-1" icon @click="doSomething()" tooltip="Export Report" mdi-icon="mdi-download" hover-background-color="#CAEAEA" />
                    </template>
                </hb-blade-header>
            </div>
            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-blade-header
    title="Quick Actions"
    title-icon="mdi-flash-circle"
    first-column-off
    left-align-title
    more-title-space
    @close="handleEmittedCloseEvent"
>
    &lt;template v-slot:right>
        &lt;HbBtn class="mr-1" icon @click="doSomething()" tooltip="Export Report" mdi-icon="mdi-download" />
    &lt;/template>
&lt;/hb-blade-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Slot Usage + Code" class="mt-4 mb-6">
            <div class="mb-4 mx-6">
                <hb-blade-header
                    back-button-off
                    close-button-off
                >
                    <template v-slot:left>
                        v-slot:left
                    </template>
                    <template v-slot:title>
                        v-slot:title
                    </template>
                    <template v-slot:right>
                        v-slot:right
                    </template>
                </hb-blade-header>
            </div>
            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-blade-header
    back-button-off
    close-button-off
>
    &lt;template v-slot:left>
        v-slot:left
    &lt;/template>
    &lt;template v-slot:title>
        v-slot:title
    &lt;/template>
    &lt;template v-slot:right>
        v-slot:right
    &lt;/template>
&lt;/hb-blade-header>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="description"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemBladeHeader",
        data: function() {
            return {
                success: false,
                description: '',
                propHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Type', value: 'type' },
                    { text: 'Default', value: 'default' },
                    { text: 'Description', value: 'description' }
                ],
                propItems: [
                    { name: 'title', type: 'string', default: 'undefined', description: 'Sets the title text.' },
                    { name: 'title-icon', type: 'string', default: 'undefined', description: 'Sets the title icon based upon mdi-code.' },
                    { name: 'back-button-off', type: 'boolean', default: 'false', description: ' If changed to true, turns off the left side back button.' },
                    { name: 'close-button-off', type: 'boolean', default: 'false', description: ' If changed to true, turns off the right side close button.' },
                    { name: 'more-title-space', type: 'boolean', default: 'false', description: ' If changed to true, allows more space for the center title content/text/slot but decreases space available to the left and right side content/text/slots.' },
                    { name: 'first-column-off', type: 'boolean', default: 'false', description: ' If changed to true, this turns off the first column. See Nav Menu example for sample usage.' },
                    { name: 'left-align-title', type: 'boolean', default: 'false', description: ' If changed to true, this turns off the center justified title and makes the title left aligned. See Nav Menu example for sample usage.' }
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' }
                ],
                slotItems: [
                    { name: 'left', description: 'Alternate usage for placing left side content.' },
                    { name: 'title', description: 'Alternate usage for placing center content (usually used as the blade title). This will override the title text and title icon set by the "title" & "title-icon" props.' },
                    { name: 'right', description: 'Alternate usage for placing right side content.' }
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@back', description: 'Event for when default left back arrow icon is clicked.' },
                    { name: '@close', description: 'Event for when default right side close icon is clicked.' }
                ]
            };
        },
        methods: {
            handleEmittedBackEvent(e){
                this.success = true;
                this.description = "This @back action is emitted from it's base level custom component.";
            },
            handleEmittedCloseEvent(e){
                this.success = true;
                this.description = "This @close action is emitted from it's base level custom component.";
            }
        }
    }
</script>

<style scoped>

</style>
